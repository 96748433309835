import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { MainContainer } from '../tags'

import Logo from '../logo'
import TopTitle from './top-title'
import TestForm from './test-form'
import Notes from './notes'

const HomeMainWrapper = styled.section`
  position: relative;
  overflow: hidden;
`

const HomeMainContainer = styled(MainContainer)`
  position: relative;
  border-top: 8px solid ${(props) => props.theme.colorOrange};
  z-index: 60;
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 2000px;
    top: -8px;
    right: 100%;
    bottom: 0;
    background-color: ${(props) => props.theme.colorWhite};
    @media (max-width: 991px) {
      content: none;
    }
  }
`

const HomeMainLayerBG = styled(BackgroundImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colorPeach};
  background-position: right top;
  border-top: 8px solid ${(props) => props.theme.colorOrange};
  z-index: 50;
`

const HomeMain = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query HomeMainQuery {
          HomeMainBG: file(relativePath: { regex: "/main_bg_full.jpg/" }) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <HomeMainWrapper>
            <HomeMainContainer>
              <Logo />
              <TopTitle />
              <TestForm />
              <Notes />
            </HomeMainContainer>
            <HomeMainLayerBG
              Tag={`div`}
              fluid={data.HomeMainBG.childImageSharp.fluid}
            />
          </HomeMainWrapper>
        )
      }}
    />
  )
}

export default HomeMain
