import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomeMain from '../components/home/home-main'
import Footer from '../components/footer'

const IndexPage = () => (
  <Layout>
    <SEO title="Wątroba się sprawdza - Essentiale Forte" />
    <HomeMain />
    <Footer />
  </Layout>
)

export default IndexPage
