import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'

const LogoWrapper = styled(Row)`
  margin-top: 25px;
`

const LogoCol = styled(Col)``

const LogoImageWrapper = styled.div`
  width: 260px;
  margin-bottom: 15px;
  @media (max-width: 575px) {
    width: 140px;
  }
`

const Logo = () => {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          LogoImage: file(relativePath: { regex: "/logo.png/" }) {
            childImageSharp {
              fluid(maxWidth: 265, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <LogoWrapper>
            <LogoCol>
              <LogoImageWrapper>
                <Img fluid={data.LogoImage.childImageSharp.fluid} />
              </LogoImageWrapper>
            </LogoCol>
          </LogoWrapper>
        )
      }}
    />
  )
}

export default Logo
