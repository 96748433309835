import React, { useState, useRef } from 'react'
import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { FirebaseContext } from 'gatsby-plugin-firebase'
import styled from 'styled-components'
import { Col, Row, FormGroup, Label, Input } from 'reactstrap'

const TestFormWrapper = styled(Row)`
  position: relative;
  padding-top: 20px;
`

const TestFormColButtonStart = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`

const TestFormColLabel = styled(Col)`
  padding-right: 15px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`

const TestFormLabel = styled(Label)`
  display: block;
  color: #010101;
  font-size: 21px;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

const TestFormColInput = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`

const TestFormGroupInput = styled(FormGroup)`
  @media (min-width: 992px) {
    padding-right: 15px;
  }
`

const TestFormInput = styled(Input)`
  height: 64px;
  font-size: 21px;
  padding-left: 35px;
  padding-right: 35px;
  border: 3px solid ${(props) => props.theme.colorWhite};
  border-radius: 15px;
  ${(props) =>
    props.isempty &&
    `
    border-color: ${props.theme.colorOrangeRed};
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 18px;
    height: 60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
    height: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
    height: 50px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
    height: 44px;
  }
  &:focus,
  &:active {
    border-color: ${(props) => props.theme.colorWhite};
  }
`

const TestFormButton = styled(Link)`
  display: inline-block;
`

const TestFormButtonStart = styled(TestFormButton)`
  display: block;
`

const TestFormButtonSubmit = styled(TestFormButton)`
  @media (max-width: 991px) {
    width: 100%;
  }
`

const TestFormButtonContent = styled.span`
  position: relative;
  display: inline-block;
  height: 64px;
  background-color: ${(props) => props.theme.colorCherry};
  color: ${(props) => props.theme.textColorWhite};
  font-size: 29px;
  font-weight: 700;
  text-align: center;
  line-height: 2.2;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: 32px;
  margin-right: 32px;
  box-shadow: 0px 20px 33px 0px rgba(237, 186, 150, 0.63);
  z-index: 20;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 26px;
    height: 60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 26px;
    height: 60px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
    height: 50px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    height: 44px;
    box-shadow: 0px 10px 16px 0px rgba(237, 186, 150, 0.63);
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    display: block;
    width: 64px;
    height: 64px;
    background-color: ${(props) => props.theme.colorCherry};
    border-radius: 50%;
    z-index: -10;
    @media (min-width: 1200px) and (max-width: 1599px) {
      width: 60px;
      height: 60px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      width: 60px;
      height: 60px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      width: 50px;
      height: 50px;
    }
    @media (max-width: 575px) {
      width: 44px;
      height: 44px;
    }
  }
  &:before {
    left: -32px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      left: -30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      left: -30px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      left: -25px;
    }
    @media (max-width: 575px) {
      left: -22px;
    }
  }
  &:after {
    right: -32px;
    @media (min-width: 1200px) and (max-width: 1599px) {
      right: -30px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      right: -30px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      right: -25px;
    }
    @media (max-width: 575px) {
      right: -22px;
    }
  }
`

const TestFormButtonContentStart = styled(TestFormButtonContent)`
  display: block;
`

const TestFormButtonContentSubmit = styled(TestFormButtonContent)`
  @media (max-width: 991px) {
    display: block;
  }
`

const TestFormColButtonContentSubmit = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
`

const TestFormColNote = styled(Col)`
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const TestFormNote = styled.p`
  color: #3d3d3d;
  font-weight: 700;
  margin-top: 35px;
  @media (min-width: 1105px) and (max-width: 1369px) {
    text-shadow: 0px 0px 10px ${(props) => props.theme.colorWhite};
  }
  @media (max-width: 1104px) {
    color: ${(props) => props.theme.colorWhite};
  }
`

const TestFormNoteOne = styled.span`
  font-size: 32px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 28px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 28px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 24px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 22px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
  }
`

const TestFormNoteTwo = styled.span`
  font-size: 22px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 20px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 18px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 14px;
  }
`

export default () => {
  const firebase = React.useContext(FirebaseContext)
  const inputTestIdRef = useRef()
  const [isEmpty, setIsEmpty] = useState(false)
  const handleTestIdClick = (e) => {
    e.preventDefault()
    let testId = inputTestIdRef.current.value

    if (testId) {
      firebase
        .firestore()
        .collection('tests')
        .doc(testId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            setIsEmpty(true)
            alert('Nie znaleziono testu o takim identyfikatorze.')
            return
          }

          if (doc.exists) {
            navigate(`/poll?id=${testId}`)
          }
        })
        .catch((error) => {
          alert('Wystąpił błąd, spróbuj ponownie później.')
        })
    } else {
      setIsEmpty(true)
      alert('Twoje pole jest puste')
    }
  }

  const handleChange = () => {
    setIsEmpty(false)
  }

  return (
    <TestFormWrapper>
      <TestFormColButtonStart xs={12} lg={8} xl={5}>
        <TestFormButtonStart to="/poll">
          <TestFormButtonContentStart>
            Rozpocznij nowy test
          </TestFormButtonContentStart>
        </TestFormButtonStart>
      </TestFormColButtonStart>
      <div className="w-100" />
      <Col xs={12}>
        <Row form>
          <TestFormColLabel xs={12} lg={8} xl={5}>
            <TestFormLabel for="inputTestID">lub</TestFormLabel>
          </TestFormColLabel>
          <div className="w-100" />
          <TestFormColInput xs={12} lg={8} xl={5}>
            <TestFormGroupInput>
              <TestFormInput
                type="text"
                name="inputTestID"
                id="inputTestID"
                placeholder="Wpisz identyfikator poprzedniego testu"
                innerRef={inputTestIdRef}
                isempty={isEmpty}
                onChange={() => handleChange()}
              />
            </TestFormGroupInput>
          </TestFormColInput>
          <TestFormColButtonContentSubmit xs={12} lg={4} xl={6}>
            <FormGroup>
              <TestFormButtonSubmit onClick={handleTestIdClick}>
                <TestFormButtonContentSubmit>
                  Kontynuuj
                </TestFormButtonContentSubmit>
              </TestFormButtonSubmit>
            </FormGroup>
          </TestFormColButtonContentSubmit>
        </Row>
      </Col>
      <TestFormColNote xs={12} xl={8}>
        <TestFormNote>
          <TestFormNoteOne>
            Wątroba to źródło witalności Twojego organizmu.
          </TestFormNoteOne>
          <br />
          <TestFormNoteTwo>
            Przekonaj się czy jest w dobrej formie, abyś Ty mógł cieszyć się
            życiem.
          </TestFormNoteTwo>
        </TestFormNote>
      </TestFormColNote>
    </TestFormWrapper>
  )
}
