import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'reactstrap'

const NotesWrapper = styled(Row)`
  position: relative;
  padding-top: 130px;
  padding-bottom: 10px;
  @media (min-width: 1200px) and (max-width: 1599px) {
    padding-top: 85px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-top: 65px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 45px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding-top: 35px;
  }
  @media (max-width: 575px) {
    padding-top: 25px;
  }
`

const NotesCol = styled(Col)`
  color: ${(props) => props.theme.textColorBlack};
  @media (min-width: 1200px) and (max-width: 1599px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`

const NotesInstruction = styled.p`
  font-size: 11px;
  font-weight: 300;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 10px;
  }
  @media (max-width: 575px) {
    font-size: 9px;
  }
`

// const NotesBeforeUse = styled.p`
//   font-size: 23px;
//   @media (min-width: 1200px) and (max-width: 1599px) {
//     font-size: 20px;
//   }
//   @media (min-width: 992px) and (max-width: 1199px) {
//     font-size: 20px;
//   }
//   @media (min-width: 768px) and (max-width: 991px) {
//     font-size: 18px;
//   }
//   @media (min-width: 576px) and (max-width: 767px) {
//     font-size: 16px;
//   }
//   @media (max-width: 575px) {
//     font-size: 14px;
//   }
// `

const NotesLinksWrapper = styled.p`
  color: #3d3d3d;
  font-size: 18px;
  margin-top: 25px;
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 15px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
  a {
    color: #3d3d3d;
    @media (max-width: 575px) {
      display: block;
    }
  }
  span {
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
    @media (max-width: 575px) {
      display: none;
    }
  }
`

const Notes = () => (
  <NotesWrapper>
    <NotesCol xs={12} xl={10}>
      <NotesInstruction>
        {`*Wykonaj test w kierunku niealkoholowego stłuszczenia wątroby`}
      </NotesInstruction>
      <NotesLinksWrapper>
        <a
          href="http://newsletter.sanofi-aventis.pl/cookies/POLITYKA-PRYWATNOSCI-ENSI20122019SanofiAventis.pdf"
          target="_blank"
          rel="noopener noreferrer"
          title="Bezpieczeństwo i dane osobowe"
        >
          Bezpieczeństwo i dane osobowe
        </a>
      </NotesLinksWrapper>
    </NotesCol>
  </NotesWrapper>
)

export default Notes
