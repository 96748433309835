import React, { Component } from 'react'
import styled from 'styled-components'
import { detect } from 'detect-browser'
import { Col, Row } from 'reactstrap'

import TitleBG from '../../images/title_bg.svg'
import TitleIEBG from '../../images/title_ie_bg.svg'

const TopTitleWrapper = styled(Row)`
  position: relative;
`
const TopTitleHeader = styled.h1`
  display: inline-block;
  background: url(${TitleBG});
  background-repeat: no-repeat;
  background-position: left top;
  background-size: contain;
  color: ${(props) => props.theme.colorCherryBlack};
  font-family: ${(props) => props.theme.fontImpact};
  font-size: 208px;
  font-weight: 700;
  line-height: 0.9;
  letter-spacing: 8px;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  padding-top: 15px;
  padding-bottom: 25px;
  ${(props) =>
    props.isIE === 'true' &&
    `
    background: url(${TitleIEBG});
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: initial;
    color: transparent;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    font-size: 140px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 128px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 100px;
  }
  @media (max-width: 575px) {
    font-size: 62px;
  }
  .withoutMasc {
    -webkit-text-fill-color: ${(props) => props.theme.colorCherryBlack};
    ${(props) =>
      props.isIE === 'true' &&
      `
    color: transparent;
    -webkit-text-fill-color: transparent;
  `}
  }
`

const TopTitleSubParagraph = styled.p`
  position: absolute;
  left: 28%;
  bottom: 35px;
  color: ${(props) => props.theme.colorCherry};
  font-size: 36px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 0;
  ${(props) =>
    props.isIE === 'true' &&
    `
    color: transparent;
  `}
  @media (min-width: 1200px) and (max-width: 1599px) {
    left: 28%;
    font-size: 24px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    left: 50%;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    left: 42%;
    font-size: 20px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    left: 39%;
    font-size: 20px;
  }
  @media (max-width: 575px) {
    left: 137px;
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
  }
`

class TopTitle extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isIE: 'false',
    }
  }

  componentDidMount() {
    const browser = detect()
    let isIE = 'false'

    if (browser && browser.name === 'ie') {
      isIE = 'true'
    }

    this.setState({ isIE: isIE })
  }

  render() {
    return (
      <TopTitleWrapper>
        <Col>
          <TopTitleHeader className="withMask" isIE={this.state.isIE}>
            <span>SPR</span>
            <span className="withoutMasc">AWDŹ&shy;</span>
            <br /> <span>SIĘ</span>
            <span className="withoutMasc">!</span>
          </TopTitleHeader>
          <TopTitleSubParagraph isIE={this.state.isIE}>
            NIE DAJ SIĘ
            <br /> NIEALKOHOLOWEMU
            <br /> STŁUSZCZENIU WĄTROBY*
          </TopTitleSubParagraph>
        </Col>
      </TopTitleWrapper>
    )
  }
}

export default TopTitle
